import React from 'react';
import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '../../utils/constant';

const { Title, Text } = Typography;

const Landing: React.FC = () => {
    const navigate = useNavigate();

    const navigateToCategory = (path: string) => {
        navigate(path);
    };

    return (
        <div
            style={{
                textAlign: 'center',
                padding: '20px',
                backgroundColor: '#f0f2f5',
                minHeight: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Title level={1} style={{ fontSize: '48px', color: '#001529', marginBottom: '20px'}}>
                Welcome to Streameast Soccer Home Page
            </Title>
            <Text type="secondary" style={{ fontSize: '20px', marginBottom: '40px' }}>
                Choose a sports category below to start streaming!
            </Text>
            <div
                style={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                <Button
                    type="primary"
                    shape='round'
                    size="large"
                    onClick={() => navigateToCategory(HOME_PATH)}
                    style={{ width: '400px', minHeight: '50px',background: '#950f0d' }}
                >
                   <span style={{  fontSize: '18px' }}>Home</span> 
                </Button>
                {/* {categories?.map((cat: any, index: any) => (
                    <Button
                    key={index}
                    type="primary"
                    size="large"
                    onClick={() => navigateToCategory(`/v2`)}
                    style={{ width: '200px', minHeight: '80px' }}
                >
                    <span style={{  fontSize: '24px' }}>{cat.label}</span>
                </Button>
                ))} */}
            </div>
        </div>
    );
};

export default Landing;
